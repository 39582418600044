import React, { useState, useEffect } from 'react';
import Book from './components/Book';
import Shelf from './components/Shelf';

import { sizeContext } from './components/SizeContext';

import "./App.css";

const App = () => {

  const [appSize, setAppSize] = useState([window.innerWidth,window.innerHeight])

  const queryParameters = new URLSearchParams(window.location.search)
  const urlbook = queryParameters.get("book")

  const sizeAppElement = {
    position: 'relative',
    width: '100%',
    overflow: 'hidden'
  }

  const handleResize = () => {
    setAppSize([window.innerWidth,window.innerHeight]);
  };

  let book = urlbook ? urlbook : 0;
  
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup: Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <div className='main-container' style ={ sizeAppElement }>
        <sizeContext.Provider value={ { appSize: appSize } }>
          { book === 0 ? <Shelf /> : <Book book_id={ book }/> }
        </sizeContext.Provider>
      </div>
  )
}

export default App
